<template>
  <div class="event_list">
    <!--  Visual Section -->
    <section class="visual_intro">
      <div class="visual_wall">
        <video
          id="eventVideo1"
          autoplay
          muted
          class="event-video"
          @ended="videoChange($event, 2)"
        >
          <source :src="getMediaFileURL(videoName1)" type="video/mp4">
        </video>

        <video
          id="eventVideo2"
          style="display:none;"
          muted
          class="event-video"
          @ended="videoChange($event, 1)"
        >
          <source :src="getMediaFileURL(videoName2)" type="video/mp4">
        </video>
      </div>
      <div class="intro_title">
        <strong class="title">EVENT</strong>
        <div class="title-desc ani-text-up" v-html="strReplace($t('content.event.Event.txt01'),'<br />')">
        </div>
      </div>
    </section>
    <!--  // Visual Section -->

    <!-- Up Coming Event Section -->
    <section class="report_group"
             v-show="swiperEventList && swiperEventList.length > 0">
      <div class="box_title">
        <h2 class="sub_title">UPCOMING EVENT</h2>
      </div>
      <!-- Swiper -->
      <Swiper ref="mySwiper"
              :options="swiperOptions"
              class="event-list"
      >
        <template v-for="event in swiperEventList">
          <swiper-slide>
            <template v-if="event.name" >
              <router-link
                :to="{ name:'EventDetail',
                  params: {eventNo:event.id},
                  query: {Date:$moment(viewDate).format('YYYYMM')}
                }"
                tag="a"
                class="event-item"
                :class="{'past-event': event.startDate < nowDate }"
              >
                <template v-if="event.startDate" >
                  <figure class="event-pic">
                    <!--
                      ie 11에서 grayscale 지원하기 위해서 svg filter 사용 (우선 전체 브라우저에 적용)
                      - https://developer.mozilla.org/en-US/docs/Web/SVG/Element/filter
                      - https://github.com/karlhorky/gray
                    -->
                    <svg
                      v-if="event.startDate < nowDate"
                      width="100%" height="100%"
                      viewBox="0 0 260 260"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <title>이벤트 이미지</title>
                      <filter id="grayscale">
                        <feColorMatrix type="saturate" values="0"/>
                      </filter>
                      <image
                        x="0" y="0"
                        width="260" height="260"
                        :xlink:href="event.thumbnailFileUrl"
                        preserveAspectRatio="none"
                        filter="url(#grayscale)" />
                    </svg>
                    <!-- // ie 11에서 grayscale 지원하기 위해서 svg filter 사용 -->
                    <img
                      v-else
                      :src="event.thumbnailFileUrl" alt="이벤트 이미지" />
                  </figure>
                  <dl class="event-info">
                    <dt>{{event.name}}</dt>
                    <dd>
                      <span>{{ getDateString(event.startDate.replace(/\./g, '-'), event.endDate.replace(/\./g, '-')) }}</span>
                    </dd>
                  </dl>
                </template>
              </router-link>
            </template>
          </swiper-slide>
        </template>

        <!-- <template v-if="dummyEventListCount > 0">
          <swiper-slide
            v-for="index in dummyEventListCount"
            :key="'dummy-' + index"
            :class="'swiper-no-swiping'"
          />
        </template> -->

        <div class="swiper-scrollbar" slot="scrollbar"></div>
      </Swiper>
      <!-- // Swiper -->
    </section>
    <!-- // Up Coming Event Section -->

    <!-- Calendar Section -->
    <section class="calendar-section" data-aos="fade-up">
      <div class="inner-lg">
        <DayGridMonthCalendar
          :viewDate="viewDate"
          :events="events"
          :eventRender="eventRender"
          @change="onChange"
        >
          <template v-slot:header="slotProps">
            <div class="calendar-header-area">
              <BaseButton type="prev" initial-view="true" @click="slotProps.prev">PREV</BaseButton>
              <div class="month">
                {{ viewRangeTitle }}
                <datepicker v-model="viewDate" ref="calendar" input-class="hide"></datepicker>
                <span class="icon-calendar" @click="openCalendar"></span>
              </div>
              <BaseButton type="next" @click="slotProps.next">NEXT</BaseButton>
            </div>
          </template>
          <template v-slot:adding>
            <StaggerTransition targets="li">
              <ul class="event-indicator ani-stagger-right">
                <li class="business">{{$t('commonCode.EV101')}}</li>
                <li class="networking">{{$t('commonCode.EV103')}}</li>
                <li class="conference">{{$t('commonCode.EV102')}}</li>
                <li class="promotion">{{$t('commonCode.EV105')}}</li>
                <li class="others">{{$t('commonCode.EV104')}}</li>
              </ul>
            </StaggerTransition>
          </template>
        </DayGridMonthCalendar>
      </div>
    </section>
    <!-- // Calendar Section -->
    <!-- Event List Section -->
    <section class="event_preview">
      <!-- <li class="group">
          <a href="#" class="anchor">
            <span class="date">09.09 09:00~20:00</span>
            <strong class="title">strong</strong>
            <small class="status">
              신청종료
            </small>
          </a>
        </li>-->
      <ul class="preview_list">
        <li class="group" v-for="event in eventList" :key="event.id">
           <router-link
              :to="{ name:'EventDetail',
                params: {eventNo:event.id},
                query: {Date:$moment(viewDate).format('YYYYMM')}
              }"
              tag="a"
              class="anchor"
            >
              <span class="date">
                {{ getDateString(event.startDate.replace(/\./g, '-'), event.endDate.replace(/\./g, '-') )}}
              </span>
              <strong class="title">
              {{event.name}}
              </strong>
              <small class="status">
                {{invaildedDate(event) ?
                $t('content.event.Event.txt14') : $t('content.event.Event.txt15')}}
              </small>
            </router-link>
        </li>
      </ul>
    </section>
    <!-- //Event List Section -->
  </div>
</template>

<script>
import EventList from '@/views/event/EventList.vue';

export default {
  name: 'MobileEventList',
  extends: EventList,
  methods: {
    invaildedDate(event) {
      return (
        this.$moment().diff(
          this.$moment(event.startDate.replace(/\./g, '-')),
          'days',
        ) > 0 || 
        this.$moment().diff(
          this.$moment(event.endDate.replace(/\./g, '-')),
          'days',
        ) > 0
      );
    },
    videoChange(event, index) {
      const videoObj = document.getElementById(`eventVideo${index}`);
      const el = event.target;
      // change display
      el.style.display = 'none';
      videoObj.style.display = 'block';
      // play
      videoObj.play();
    },
  },
};
</script>

<style>
.event-info dd .date {font-size:100%}

.swiper-scrollbar {
  width: 100% !important;
}
.event-pic img {
  width: 260px;
  height: 260px;
}
.destroy-swiper .swiper-wrapper {display:block; text-align:center}
.destroy-swiper .swiper-slide {display:inline-block;width: initial;margin: 0 20px;}
/* >>> .destroy-swiper .event-info {display:inline-block} */

.calendar-section a {cursor: pointer;}
.vdp-datepicker__calendar header .prev .default:after {
  border-bottom-color: #000 !important;
  border-right-color: #000 !important;
}
.vdp-datepicker__calendar header .next .default:after {
  border-top-color: #000 !important;
  border-left-color: #000 !important;
}
.event_list .event-list .swiper-slide { width: 25rem; height: 35rem !important; overflow: hidden; }
.event-info dt {
  display: block !important;
  max-width: 260px;
  max-height: 56px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.75;
  letter-spacing: normal;
  color: #666;
  transition: 0.3s;
  white-space: nowrap;
}
.event-info dd {
  margin-top: 10px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.54;
  letter-spacing: normal;
  color: #666;
}
</style>
